import React from "react";

import feedback_q04 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_04.pug';

class T_Feedback_Q04 extends React.Component {
    render() {
        const checkbox = {
            name: 'feedback-skincare-habits',
            values: [
                'メイク落としは\n毎日落としている',
                '洗顔は朝晩毎日\nおこなっている',
                '朝の洗顔後は化粧水・乳液で\n必ずケアする',
                '夜の洗顔時は化粧水・乳液で\n必ずケアする',
                '外出時は日焼け止めアイテムを\n必ず使用する',
            ]
        };
        return feedback_q04.call(this, {        // ← use transpiled function
            checkbox
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q04