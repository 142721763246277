import React from "react";

import feedback_q06 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_06.pug';

class T_Feedback_Q06 extends React.Component {
    render() {
        const radio = {
            name: 'feedback-sleep-time',
            values: [
                '8時間以上',
                '6時間から8時間',
                '4時間から6時間',
                '4時間以下'
            ]
        };
        return feedback_q06.call(this, {        // ← use transpiled function
            radio
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q06