import React from "react";

import feedback_number from '../../pug/_object/_project/_feedback/_t-feedback__number.pug';

class T_Feedback_Number extends React.Component {
    render() {
        return feedback_number.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Number