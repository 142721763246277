import React from 'react';

export default function() {
  return (
    <div className="p-questionsNumber js-feedbackQuestions__number">
      <div className="p-questionsNumber__current js-feedbackQuestions__number__current"></div>
      <ul className="p-questionsNumber__dots js-feedbackQuestions__number__dots"></ul>
      <div className="p-questionsNumber__total js-feedbackQuestions__number__total"></div>
    </div>
  );
}
