import React from 'react';

export default function(__params = {}) {
  const { name } = __params;
  return (
    <div className="t-feedback__announce">
      <ul className="p-feedbackAnnounce js-feedbackAnnounce">
        <li data-slug="skincare">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h2 className="p-feedbackAnnounce__ttl js-feedbackAnnounce__ttl">
                  {name}さん
                  <br />
                  次はあなたのスキンケア習慣
                  <br className="u-hide-pc" />
                  について教えてください
                </h2>
                <div className="p-feedbackAnnounce__pic js-feedbackAnnounce__pic">
                  <img src="/images/feedback/p-feedbackAnnounce__pic.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </li>
        <li data-slug="lifestyle">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h2 className="p-feedbackAnnounce__ttl js-feedbackAnnounce__ttl">
                  {name}さん
                  <br />
                  続いてはあなたの生活習慣
                  <br className="u-hide-pc" />
                  について教えてください
                </h2>
                <div className="p-feedbackAnnounce__pic js-feedbackAnnounce__pic">
                  <img src="/images/feedback/p-feedbackAnnounce__pic.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
