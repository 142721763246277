import React from "react";

import feedback_q05 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_05.pug';

class T_Feedback_Q05 extends React.Component {
    render() {
        const checkbox = {
            name: 'feedback-use-items',
            inputs: [ // dataAloneは固定
                { value: '洗顔', dataAlone: false },
                { value: 'クレンジング', dataAlone: false },
                { value: '拭き取り化粧水', dataAlone: false },
                { value: '美容液', dataAlone: false },
                { value: 'アイクリーム', dataAlone: false },
                { value: 'フェイスマスク', dataAlone: false },
                { value: 'ナイトクリーム', dataAlone: false },
                { value: '美肌目的のサプリメント', dataAlone: false },
                { value: '特になし', dataAlone: true }
            ]
        };
        return feedback_q05.call(this, {        // ← use transpiled function
            checkbox
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q05