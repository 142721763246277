import React from 'react';

export default function() {
  return (
    <div className="t-feedback__wait is-active">
      <div className="p-questionsWait p-questionsWait--has-ttl js-feedbackQuestions__wait">
        <div className="p-questionsWait__ttl">
          <h2 className="p-title p-title--30">RECORDING!</h2>
          <p className="p-questionsWait__ttl__jp c-spacer--pt20 u-tac">
            最新のあなたの肌状態を
            <br className="u-hide-pc" />
            保存しています...
          </p>
        </div>
        <div className="p-questionsWait__loader">
          <div className="p-questionsWait__outline"></div>
          <div className="p-questionsWait__progress">
            <div className="c-circleProgress js-feedbackQuestions__wait__progress">
              <div className="c-circleProgress__center"></div>
            </div>
          </div>
          <div className="p-questionsWait__text type-save">
            <span className="per"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
