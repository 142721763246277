import React from "react";

import feedback_q01 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_01.pug';

class T_Feedback_Q01 extends React.Component {
    render() {
        const questionsResetRadioName = 'feedback-reset-trace-01'; // 『変更する/しない』 ラジオボタンname

        const daytime_dry_skin = {
            confirm: '乾燥する',
            defaultValue: '83.33333333'
        };
        const daytime_greasy_skin = {
            confirm: '脂っぽくない',
            defaultValue: '16.66666667'
        };
        const summer_dry_skin = [
            { value: '気になることが多い',                     defaultChecked: false },
            { value: 'エアコンが当たったりすると、たまに気になる', defaultChecked: true },
            { value: '気にならない',                          defaultChecked: false }
        ];
        const winter_dry_skin = [
            { value: '常に気になる',                  defaultChecked: false },
            { value: '湿気か低い日など、たまに気になる', defaultChecked: true },
            { value: '気にならない',                  defaultChecked: false }
        ];
        const acne_position = [ // slugは画像パスで使用、dataAloneは固定
            { value: 'おでこ',        slug: 'forehead', defaultChecked: true,   dataAlone: false },
            { value: 'あご周り',      slug: 'chin',     defaultChecked: true,   dataAlone: false },
            { value: 'フェイスライン', slug: 'faceline', defaultChecked: true,   dataAlone: false },
            { value: 'ほほ',         slug: 'cheek',    defaultChecked: true,   dataAlone: false },
            { value: '特になし',      slug: 'none',     defaultChecked: false,  dataAlone: true }
        ];
        const pore_type = [ // slugは画像パスで使用、dataAloneは固定
            { value: '開き毛穴',      slug: 'open',     defaultChecked: true,   dataAlone: false },
            { value: '詰まり毛穴',    slug: 'clog',     defaultChecked: true,   dataAlone: false },
            { value: 'たるみ毛穴',    slug: 'slack',    defaultChecked: true,   dataAlone: false },
            { value: '黒ずみ毛穴',    slug: 'black',    defaultChecked: true,   dataAlone: false },
            { value: '特になし',      slug: 'none',     defaultChecked: false,  dataAlone: true },
        ];
        return feedback_q01.call(this, {        // ← use transpiled function
            questionsResetRadioName,
            daytime_dry_skin,
            daytime_greasy_skin,
            summer_dry_skin,
            winter_dry_skin,
            acne_position,
            pore_type
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q01