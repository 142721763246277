import React from "react";

import feedback_q07 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_07.pug';

class T_Feedback_Q07 extends React.Component {
    render() {
        const checkbox = {
            name: 'feedback-eating-habits',
            inputs: [
                { value: 'スナック菓子やファーストフードをよく食べる', dataAlone: false },
                { value: '甘いデザートやお菓子を\nよく食べる', dataAlone: false },
                { value: 'サラダやフルーツを\nよく食べる', dataAlone: false },
                { value: 'お酒をよく飲む', dataAlone: false },
                { value: '食事は基本1日3回とる', dataAlone: false },
                { value: '一日に摂取する水の量\n1.5L以下である', dataAlone: false },
                { value: '特になし', dataAlone: true }
            ]
        };
        return feedback_q07.call(this, {        // ← use transpiled function
            checkbox
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q07