import React from 'react';

export default function(__params = {}) {
  const { textareaName } = __params;
  return (
    <li className="p-questionsBody">
      <div className="p-questionsBody__head">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <h3 className="p-questionsBody__ttl">
                <span>
                  お疲れ様でした。
                  <br className="u-hide-pc" />
                  最後の質問です。
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__middle">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-14 md-10">
              <small className="u-block u-tac c-spacer--pb50">
                もしお肌のお悩みやスキンケアのご相談が
                <br className="u-hide-pc" />
                あればコメントをご記載ください。
              </small>
              <textarea
                className="is-placeholder-grey"
                name={textareaName}
                rows="7"
                placeholder="お肌のお悩みやスキンケアのご相談があればコメントをご記載ください。"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__foot">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-5 md-4 xs-1">
              <button className="js-feedbackQuestions__slide__back p-button p-button--arw-left p-button--white">
                <span className="p-button__label u-hide-sp">BACK</span>
              </button>
            </div>
            <div className="c-grid__col lg-9 md-6 xs-5">
              <button className="js-feedbackQuestions__slide__next p-button p-button--arw-right">
                <span className="p-button__label">NEXT</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
