import React from 'react';

export default function() {
  return (
    <div className="t-feedback__entrance t-feedback__table js-feedback__entrance u-tac">
      <div className="t-feedback__table__middle">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <h2>
                <span className="u-block p-title--38 p-title--thin p-title--white-shadow">再診断をしましょう</span>
                <span className="u-block c-spacer--pt10">10 QUESTIONS + SKIN CHECK</span>
              </h2>
              <div className="t-feedback__entrance__illust c-spacer--pt70 c-spacer--pt20-sp c-spacer--pb50 c-spacer--pb20-sp">
                <img src="/images/feedback/t-feedback__illust.png" alt="" />
              </div>
            </div>
          </div>
          <div className="c-grid__row">
            <div className="c-grid__col c-spacer--pb30 c-spacer--pb20-sp">
              <p>
                ここからは
                <br className="u-hide-pc" />
                もっとぴったりなスキンケアと出会うため、
                <br />
                いまの肌状態を見直すカウンセリングを行います。
              </p>
            </div>
          </div>
          <div className="c-grid__row c-grid__row--inner-pd-sp">
            <div className="c-grid__col lg-7 md-3"></div>
            <div className="c-grid__col lg-10 md-6">
              <button
                className="p-button p-button--white p-button--key-text p-button--circle-right js-feedback__start"
                type="button"
              >
                <span className="p-button__label">再診断する</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
