import React from "react";

import feedback_wait from '../../pug/_object/_project/_feedback/_t-feedback__wait.pug';

class T_Feedback_Wait extends React.Component {
    render() {
        return feedback_wait.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Wait