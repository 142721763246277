import React from "react";

import feedback_confirm from '../../pug/_object/_project/_feedback/_t-feedback__confirm.pug';

class T_Feedback_Confirm extends React.Component {
    render() {
        const name = 'KANAKO';
        return feedback_confirm.call(this, {        // ← use transpiled function
            name
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Confirm