import React from 'react';

export default function(__params = {}) {
  const { radio } = __params;
  return (
    <li className="p-questionsBody">
      <div className="p-questionsBody__head">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <h3 className="p-questionsBody__ttl">
                直近1ヶ月の平均睡眠時間を
                <br className="u-hide-pc" />
                教えてください
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__middle">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-14 md-10">
              <ul className="p-formDl__two-cols">
                {(radio.values || []).map((value, i) => (
                  <li className="c-checkButton c-checkButton--text" key={i}>
                    <input type="radio" name={radio.name} value={value} />
                    <label>
                      <span className="u-preline-sp">{value}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__foot">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-5 md-4 xs-1">
              <button className="js-feedbackQuestions__slide__back p-button p-button--arw-left p-button--white">
                <span className="p-button__label u-hide-sp">BACK</span>
              </button>
            </div>
            <div className="c-grid__col lg-9 md-6 xs-5">
              <button className="js-feedbackQuestions__slide__next p-button p-button--arw-right" data-required="radio">
                <span className="p-button__label">NEXT</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
