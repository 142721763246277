import React from "react";

import feedback_face from '../../pug/_object/_project/_feedback/_t-feedback__face.pug';

class T_Feedback_Face extends React.Component {
    render() {
        return feedback_face.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Face