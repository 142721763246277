import React from "react"
// import { Link } from "gatsby"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import T_FORMPAGE_BG from "../../../../../components/t-formpage_bg"
import T_FEEDBACK_CONFIRM from "../../../../../components/feedback/t-feedback_confirm"
import T_FEEDBACK_NUMBER from "../../../../../components/feedback/t-feedback_number"
import T_FEEDBACK_Q01 from "../../../../../components/feedback/t-feedback_q01"
import T_FEEDBACK_Q02 from "../../../../../components/feedback/t-feedback_q02"
import T_FEEDBACK_Q03 from "../../../../../components/feedback/t-feedback_q03"
import T_FEEDBACK_Q04 from "../../../../../components/feedback/t-feedback_q04"
import T_FEEDBACK_Q05 from "../../../../../components/feedback/t-feedback_q05"
import T_FEEDBACK_Q06 from "../../../../../components/feedback/t-feedback_q06"
import T_FEEDBACK_Q07 from "../../../../../components/feedback/t-feedback_q07"
import T_FEEDBACK_Q08 from "../../../../../components/feedback/t-feedback_q08"
import T_FEEDBACK_ENTRANCE from "../../../../../components/feedback/t-feedback_entrance"
import T_FEEDBACK_FACE from "../../../../../components/feedback/t-feedback_face"
import T_FEEDBACK_ANNOUNCE from "../../../../../components/feedback/t-feedback_announce"
import T_FEEDBACK_WAIT from "../../../../../components/feedback/t-feedback_wait"
const HotaruFeedbackPage = () => (
  <Layout sectionClasses={"t-feedback t-formpage js-feedback"} dataNamespace={"feedback"} isShowFooter={false}>
    <SEO title="FEEDBACK QUESTIONS" />
    <T_FORMPAGE_BG />
    <form className="p-form" action="" method="post">
      <T_FEEDBACK_CONFIRM />
      <div className="t-feedback__questions js-feedbackQuestions">
        <T_FEEDBACK_NUMBER />
        <h2 className="t-formpage__ttl">FEEDBACK QUESTIONS</h2>
        <ul className="js-feedbackQuestions__forms js-feedbackQuestions__slide is-notModal">
          <T_FEEDBACK_Q01 />
          <T_FEEDBACK_Q02 />
          <T_FEEDBACK_Q03 />
          <T_FEEDBACK_Q04 />
          <T_FEEDBACK_Q05 />
          <T_FEEDBACK_Q06 />
          <T_FEEDBACK_Q07 />
          <T_FEEDBACK_Q08 />
        </ul>
      </div>
    </form>
    <T_FEEDBACK_ENTRANCE />
    <T_FEEDBACK_FACE />
    <T_FEEDBACK_ANNOUNCE />
    <T_FEEDBACK_WAIT />
  </Layout>
)

export default HotaruFeedbackPage
