import React from "react";

import feedback_entrance from '../../pug/_object/_project/_feedback/_t-feedback__entrance.pug';

class T_Feedback_Entrance extends React.Component {
    render() {
        return feedback_entrance.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Entrance