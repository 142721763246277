import React from "react";

import feedback_announce from '../../pug/_object/_project/_feedback/_t-feedback__announce.pug';

class T_Feedback_Announce extends React.Component {
    render() {
        const name = 'KANAKO';
        return feedback_announce.call(this, {        // ← use transpiled function
            name
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Announce