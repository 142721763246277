import React from 'react';

export default function(__params = {}) {
  const {
    acne_position,
    daytime_dry_skin,
    daytime_greasy_skin,
    pore_type,
    questionsResetRadioName,
    summer_dry_skin,
    winter_dry_skin
  } = __params;
  return (
    <li className="p-questionsBody p-questionsReset js-questionsReset">
      <div className="p-questionsReset__confirm js-questionsReset__confirm">
        <div className="p-questionsBody__head">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h3 className="p-questionsBody__ttl">
                  お肌の状態に変化は
                  <br className="u-hide-pc" />
                  ありましたか？
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__middle">
          <div className="c-grid type-confirm">
            <div className="c-grid__row">
              <div className="c-grid__col lg-6 md-2 xs-0"></div>
              <div className="c-grid__col lg-12 md-8">
                <dl className="p-formDl">
                  <dt>日中のほほの状態は？（乾燥）</dt>
                  <dd>
                    <p className="p-formDl__confirm">{daytime_dry_skin.confirm}</p>
                  </dd>
                  <dt>日中のほほの状態は？（脂っぽさ）</dt>
                  <dd>
                    <p className="p-formDl__confirm">{daytime_greasy_skin.confirm}</p>
                  </dd>
                  <dt>夏のお肌の乾燥は？</dt>
                  <dd>
                    <ul>
                      {(summer_dry_skin || []).map((input, i) => (
                        <li key={i}>{input.defaultChecked && <p className="p-formDl__confirm">{input.value}</p>}</li>
                      ))}
                    </ul>
                  </dd>
                  <dt>冬のお肌の乾燥は？</dt>
                  <dd>
                    <ul>
                      {(winter_dry_skin || []).map((input, i) => (
                        <li key={i}>{input.defaultChecked && <p className="p-formDl__confirm">{input.value}</p>}</li>
                      ))}
                    </ul>
                  </dd>
                  <dt>ニキビができやすい場所</dt>
                  <dd>
                    <ul className="p-formDl__two-cols type-odd">
                      {(acne_position || []).map((input, i) => (
                        <li key={i}>{input.defaultChecked && <p className="p-formDl__confirm">{input.value}</p>}</li>
                      ))}
                    </ul>
                  </dd>
                  <dt>毛穴の悩み</dt>
                  <dd>
                    <ul className="p-formDl__two-cols type-odd">
                      {(pore_type || []).map((input, i) => (
                        <li key={i}>{input.defaultChecked && <p className="p-formDl__confirm">{input.value}</p>}</li>
                      ))}
                    </ul>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__foot">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-1 xs-0"></div>
              <div className="c-grid__col lg-14 md-10">
                <p className="c-spacer--pb20 c-spacer--pb20-sp u-tac">季節や環境の変化があれば変更しましょう </p>
              </div>
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-7 md-3 xs-1"></div>
              <div className="c-grid__col lg-10 md-6 xs-4">
                <div className="p-button p-button--radio p-button--arw-right">
                  <input
                    className="js-questionsReset__radio"
                    type="radio"
                    name={questionsResetRadioName}
                    value="変更する"
                  />
                  <label className="p-button__label">変更する</label>
                </div>
                <div className="p-button p-button--radio p-button--arw-right p-button--white c-spacer--mt10 c-spacer--mt10-sp">
                  <input
                    className="js-questionsReset__radio"
                    type="radio"
                    name={questionsResetRadioName}
                    value="変更しない"
                  />
                  <label className="p-button__label">変更しない</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsReset__edit js-questionsReset__edit">
        <div className="p-questionsBody__head">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h3 className="p-questionsBody__ttl">
                  <span>
                    お肌の状態に変化は
                    <br className="u-hide-pc" />
                    ありましたか？
                  </span>
                  <small className="u-block">変化があった項目は変更しましょう</small>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__middle">
          <div className="c-grid type-edit-01">
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-1 xs-0"></div>
              <div className="c-grid__col lg-14 md-10">
                <dl className="p-formDl p-formDl--type-edit">
                  <dt>
                    日中のほほの肌状態について
                    <br className="u-hide-pc" />
                    教えてください
                  </dt>
                  <dd>
                    <div className="p-form__range">
                      <input
                        className="c-rangeSlider js-rangeSlider"
                        type="range"
                        name="daytime-dry-skin"
                        min="0"
                        max="100"
                        step="16.666666666666667"
                        defaultValue={daytime_dry_skin.defaultValue}
                      />
                      <label>
                        <span>乾燥しない</span>
                        <span>乾燥する</span>
                      </label>
                    </div>
                    <div className="p-form__range">
                      <input
                        className="c-rangeSlider js-rangeSlider"
                        type="range"
                        name="daytime-greasy-skin"
                        min="0"
                        max="100"
                        step="16.666666666666667"
                        defaultValue={daytime_greasy_skin.defaultValue}
                      />
                      <label>
                        <span>脂っぽくない</span>
                        <span>脂っぽい</span>
                      </label>
                    </div>
                  </dd>
                  <dt>夏に肌の乾燥が気になりますか？</dt>
                  {(summer_dry_skin || []).map((input, i) => (
                    <dd className="p-form__radio c-checkButton c-checkButton--text" key={i}>
                      <input
                        type="radio"
                        name="summer-dry-skin"
                        value={input.value}
                        defaultChecked={input.defaultChecked}
                      />
                      <label>
                        <span>{input.value}</span>
                      </label>
                    </dd>
                  ))}
                  <dt>冬に肌の乾燥が気になりますか？</dt>
                  {(winter_dry_skin || []).map((input, i) => (
                    <dd className="p-form__radio c-checkButton c-checkButton--text" key={i}>
                      <input
                        type="radio"
                        name="winter-dry-skin"
                        value={input.value}
                        defaultChecked={input.defaultChecked}
                      />
                      <label>
                        <span>{input.value}</span>
                      </label>
                    </dd>
                  ))}
                  <dt>
                    ニキビができやすい箇所を
                    <br className="u-hide-pc" />
                    選択して下さい。（複数選択可）
                  </dt>
                </dl>
              </div>
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-2 md-1 xs-0"></div>
              {(acne_position || []).map((input, i) => (
                <div className="c-grid__col lg-4 md-2 xs-3" key={i}>
                  <div className="c-checkButton c-checkButton--card c-spacer--pt10 c-spacer--pt10-sp">
                    <input
                      type="checkbox"
                      name="acne-position"
                      value={input.value}
                      defaultChecked={input.defaultChecked}
                      data-alone={input.dataAlone}
                    />
                    <label>
                      <img src={'/images/questions/c-checkButton--card--acne-' + input.slug + '.png'} alt="" />
                      <span>{input.value}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-1 xs-0"></div>
              <div className="c-grid__col lg-14 md-10">
                <dl className="p-formDl p-formDl--type-edit">
                  <dt>
                    悩んでいる毛穴を
                    <br className="u-hide-pc" />
                    選択して下さい。（複数選択可）
                  </dt>
                </dl>
              </div>
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-2 md-1 xs-0"></div>
              {(pore_type || []).map((input, i) => (
                <div className="c-grid__col lg-4 md-2 xs-3" key={i}>
                  <div className="c-checkButton c-checkButton--card c-spacer--pt10 c-spacer--pt10-sp">
                    <input
                      type="checkbox"
                      name="pore-type"
                      value={input.value}
                      defaultChecked={input.defaultChecked}
                      data-alone={input.dataAlone}
                    />
                    <label>
                      <img src={'/images/questions/c-checkButton--card--pore-' + input.slug + '.png'} alt="" />
                      <span>{input.value}</span>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="p-questionsBody__foot">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-7 md-3 xs-0"></div>
              <div className="c-grid__col lg-10 md-6">
                <button className="js-feedbackQuestions__slide__next p-button p-button--arw-right">
                  <span className="p-button__label">変更を保存して次へ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
