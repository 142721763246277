import React from "react";

import feedback_q08 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_08.pug';

class T_Feedback_Q08 extends React.Component {
    render() {
        const textareaName = 'feedback-consult-comment';
        return feedback_q08.call(this, {        // ← use transpiled function
            textareaName
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q08