import React from "react";

import feedback_q03 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_03.pug';

class T_Feedback_Q03 extends React.Component {
    render() {
        const questionsResetRadioName = 'feedback-reset-trace-03'; // 『変更する/しない』 ラジオボタンname

        const confirm = {
            skin_trouble_1st: 'シミ・そばかす',
            skin_trouble_2nd: 'カサつき',
            skin_trouble_3rd: '毛穴の目立ち'
        }
        return feedback_q03.call(this, {        // ← use transpiled function
            questionsResetRadioName,
            confirm
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q03