import React from 'react';

export default function(__params = {}) {
  const { name } = __params;
  return (
    <div className="t-feedback__confirm js-feedbackConfirm">
      <div className="p-questionsNumber js-feedbackConfirm__number">
        <div className="p-questionsNumber__current js-feedbackConfirm__number__current"></div>
        <ul className="p-questionsNumber__dots js-feedbackConfirm__number__dots"></ul>
        <div className="p-questionsNumber__total js-feedbackConfirm__number__total"></div>
      </div>
      <ul className="js-feedbackConfirm__forms js-feedbackConfirm__slide is-notModal">
        <li className="p-questionsBody">
          <div className="p-questionsBody__head">
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="c-grid__col">
                  <h3 className="p-questionsBody__ttl">
                    {name}さん
                    <br />
                    次回お届けするローションの
                    <br className="u-hide-pc" />
                    処方を変更しますか？
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="p-questionsBody__middle">
            <div className="c-grid type-reset-confirm">
              <div className="c-grid__row">
                <div className="c-grid__col lg-5 md-1 xs-0"></div>
                <div className="c-grid__col lg-14 md-10">
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input type="radio" name="lotion-custom-confirm" value="変更したい" />
                    <label>
                      <span>変更したい</span>
                      <small className="u-block">現在とは異なる処方でお客様に調合させていただきます。</small>
                    </label>
                  </div>
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input type="radio" name="lotion-custom-confirm" value="おまかせ" />
                    <label>
                      <span>おまかせ</span>
                      <small className="u-block">
                        HOTARU PARSONALIZEDシステムに合わせてお客様へ調合させていただきます。
                        <br />
                        お肌の状態に変化がある場合は、こちらをご選択ください。
                      </small>
                    </label>
                  </div>
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input
                      className="js-feedbackConfirm__noChange"
                      type="radio"
                      name="lotion-custom-confirm"
                      value="変更しない"
                    />
                    <label>
                      <span>変更しない</span>
                      <small className="u-block">次回も同様の処方でお客様へお届けいたします。</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-questionsBody__foot">
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="c-grid__col lg-7 md-3 xs-1"></div>
                <div className="c-grid__col lg-10 md-6 xs-4">
                  <button
                    className="js-feedbackConfirm__slide__next p-button p-button--arw-right"
                    data-required="radio"
                  >
                    <span className="p-button__label">NEXT</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className="p-questionsBody">
          <div className="p-questionsBody__head">
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="c-grid__col">
                  <h3 className="p-questionsBody__ttl">
                    続いては
                    <br className="u-hide-pc" />
                    モイスチャライザーについて
                    <br />
                    次回お届けする処方を変更しますか？
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="p-questionsBody__middle">
            <div className="c-grid type-reset-confirm">
              <div className="c-grid__row">
                <div className="c-grid__col lg-5 md-1 xs-0"></div>
                <div className="c-grid__col lg-14 md-10">
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input type="radio" name="moisturizer-custom-confirm" value="変更したい" />
                    <label>
                      <span>変更したい</span>
                      <small className="u-block">現在とは異なる処方でお客様に調合させていただきます。</small>
                    </label>
                  </div>
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input type="radio" name="moisturizer-custom-confirm" value="おまかせ" />
                    <label>
                      <span>おまかせ</span>
                      <small className="u-block">
                        HOTARU PARSONALIZEDシステムに合わせてお客様へ調合させていただきます。
                        <br />
                        お肌の状態に変化がある場合は、こちらをご選択ください。
                      </small>
                    </label>
                  </div>
                  <div className="p-form__radio c-checkButton c-checkButton--text">
                    <input
                      className="js-feedbackConfirm__noChange"
                      type="radio"
                      name="moisturizer-custom-confirm"
                      value="変更しない"
                    />
                    <label>
                      <span>変更しない</span>
                      <small className="u-block">次回も同様の処方でお客様へお届けいたします。</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-questionsBody__foot">
            <div className="c-grid">
              <div className="c-grid__row">
                <div className="c-grid__col lg-5 md-1 xs-0"></div>
                <div className="c-grid__col lg-5 md-4 xs-1">
                  <button className="js-feedbackConfirm__slide__back p-button p-button--arw-left p-button--white">
                    <span className="p-button__label u-hide-sp">BACK</span>
                  </button>
                </div>
                <div className="c-grid__col lg-9 md-6 xs-5">
                  <button
                    className="js-feedbackConfirm__slide__next p-button p-button--arw-right"
                    data-required="radio"
                  >
                    <span className="p-button__label">NEXT</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
