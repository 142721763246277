import React from 'react';

export default function(__params = {}) {
  const { confirm, questionsResetRadioName } = __params;
  return (
    <li className="p-questionsBody p-questionsReset js-questionsReset">
      <div className="p-questionsReset__confirm js-questionsReset__confirm">
        <div className="p-questionsBody__head">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h3 className="p-questionsBody__ttl">
                  お肌のトラブルに
                  <br className="u-hide-pc" />
                  変化はありましたか？
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__middle">
          <div className="c-grid type-confirm">
            <div className="c-grid__row">
              <div className="c-grid__col lg-6 md-2 xs-0"></div>
              <div className="c-grid__col lg-12 md-8">
                <dl className="p-formDl">
                  <dt>１番に気になる</dt>
                  <dd>
                    <p className="p-formDl__confirm">{confirm.skin_trouble_1st}</p>
                  </dd>
                  <dt>２番に気になる</dt>
                  <dd>
                    <p className="p-formDl__confirm">{confirm.skin_trouble_2nd}</p>
                  </dd>
                  <dt>３番に気になる</dt>
                  <dd>
                    <p className="p-formDl__confirm">{confirm.skin_trouble_3rd}</p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__foot">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-1 xs-0"></div>
              <div className="c-grid__col lg-14 md-10">
                <p className="c-spacer--pb20 c-spacer--pb20-sp u-tac">季節や環境の変化があれば変更しましょう </p>
              </div>
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-7 md-3 xs-1"></div>
              <div className="c-grid__col lg-10 md-6 xs-4">
                <div className="p-button p-button--radio p-button--arw-right">
                  <input
                    className="js-questionsReset__radio"
                    type="radio"
                    name={questionsResetRadioName}
                    value="変更する"
                  />
                  <label className="p-button__label">変更する</label>
                </div>
                <div className="p-button p-button--radio p-button--arw-right p-button--white c-spacer--mt10 c-spacer--mt10-sp">
                  <input
                    className="js-questionsReset__radio"
                    type="radio"
                    name={questionsResetRadioName}
                    value="変更しない"
                  />
                  <label className="p-button__label">変更しない</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsReset__edit js-questionsReset__edit">
        <div className="p-questionsBody__head">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col">
                <h3 className="p-questionsBody__ttl">
                  <span>
                    お肌のトラブルに
                    <br className="u-hide-pc" />
                    変化はありましたか？
                  </span>
                  <small className="u-block c-spacer--pt10 c-spacer--pt10-sp">
                    現在悩んでいる肌トラブルを
                    <br className="u-hide-pc" />
                    ３つ教えてください
                  </small>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__middle">
          <div className="c-grid type-edit-03">
            <div className="c-grid__row">
              <div className="c-grid__col lg-3 md-0 xs-0"></div>
              <div className="c-grid__col lg-18">
                <div className="p-questionsBalls js-feedbackQuestions__balls">
                  <canvas className="p-questionsBalls__canvas" id="cvs-feedbackQuestions__balls"></canvas>
                  <ul className="p-questionsBalls__list js-feedbackQuestions__balls__list">
                    <li data-value="stains-and-freckles">
                      シミ
                      <br />
                      そばかす
                    </li>
                    <li data-value="dry">カサつき</li>
                    <li data-value="fine-wrinkles">小じわ</li>
                    <li data-value="pores">
                      毛穴の
                      <br />
                      目立ち
                    </li>
                    <li data-value="makeup-deterioration-and-shine">
                      化粧崩れ
                      <br />
                      テカリ
                    </li>
                    <li data-value="firmness-and-sagging">
                      ハリのなさ
                      <br />
                      たるみ
                    </li>
                    <li data-value="dullness">くすみ</li>
                    <li data-value="acne-and-eruption">
                      ニキビ
                      <br />
                      吹き出物
                    </li>
                    <li data-value="stiff">ごわつき</li>
                  </ul>
                  <input
                    className="js-feedbackQuestions__balls__input1"
                    type="hidden"
                    name="skin-trouble-1st"
                    value=""
                  />
                  <input
                    className="js-feedbackQuestions__balls__input2"
                    type="hidden"
                    name="skin-trouble-2nd"
                    value=""
                  />
                  <input
                    className="js-feedbackQuestions__balls__input3"
                    type="hidden"
                    name="skin-trouble-3rd"
                    value=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-questionsBody__foot">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-1 xs-0"></div>
              <div className="c-grid__col lg-14 md-10 c-spacer--pb30 c-spacer--pb20-sp">
                <button className="p-questionsBalls__reset js-feedbackQuestions__balls__reset" type="button">
                  選択をリセット
                </button>
              </div>
            </div>
          </div>
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-7 md-3 xs-0"></div>
              <div className="c-grid__col lg-10 md-6">
                <button
                  className="js-feedbackQuestions__slide__next p-button p-button--arw-right"
                  data-required="balls"
                >
                  <span className="p-button__label">変更を保存して次へ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
