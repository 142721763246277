import React from 'react';

export default function(__params = {}) {
  const { checkbox } = __params;
  return (
    <li className="p-questionsBody">
      <div className="p-questionsBody__head">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col">
              <h3 className="p-questionsBody__ttl">
                <span>お使いのアイテムをお選びください</span>
                <small className="u-block">（複数選択可能）</small>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__middle">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-14 md-10">
              <ul className="p-formDl__two-cols type-odd">
                {(checkbox.inputs || []).map((input, i) => (
                  <li className="c-checkButton c-checkButton--text" key={i}>
                    <input type="checkbox" name={checkbox.name} value={input.value} data-alone={input.dataAlone} />
                    <label>
                      <span>{input.value}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-questionsBody__foot">
        <div className="c-grid">
          <div className="c-grid__row">
            <div className="c-grid__col lg-5 md-1 xs-0"></div>
            <div className="c-grid__col lg-5 md-4 xs-1">
              <button className="js-feedbackQuestions__slide__back p-button p-button--arw-left p-button--white">
                <span className="p-button__label u-hide-sp">BACK</span>
              </button>
            </div>
            <div className="c-grid__col lg-9 md-6 xs-5">
              <button
                className="js-feedbackQuestions__slide__next p-button p-button--arw-right"
                data-required="checkbox"
              >
                <span className="p-button__label">NEXT</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
