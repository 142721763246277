import React from "react";

import feedback_q02 from '../../pug/_object/_project/_feedback/_feedbackQuestions__forms/_02.pug';

class T_Feedback_Q02 extends React.Component {
    render() {
        const questionsResetRadioName = 'feedback-reset-trace-02'; // 『変更する/しない』 ラジオボタンname

        const checkbox = {
            name: 'environment',
            inputs_row1: [ // 1段目、slugはアイコンclass名で使用
                { value: '空気の乾燥', slug: 'dry', defaultChecked: true },
                { value: '花粉', slug: 'pollen', defaultChecked: false },
                { value: '大気汚染', slug: 'air', defaultChecked: false },
                { value: '紫外線', slug: 'uv', defaultChecked: false }
            ],
            inputs_row2: [ // 2段目、slugはアイコンclass名で使用
                { value: '不規則な食事', slug: 'meal', defaultChecked: false },
                { value: '疲れ・睡眠不足', slug: 'sleep', defaultChecked: true },
                { value: 'ストレス', slug: 'stress', defaultChecked: false },
                { value: '運動不足', slug: 'exercise', defaultChecked: false }
            ]
        };
        return feedback_q02.call(this, {        // ← use transpiled function
            questionsResetRadioName,
            checkbox
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Feedback_Q02